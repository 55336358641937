////////////////////
// Module Imports //
//////////////////// 
import "smoothscroll-polyfill";
import PubSub from "pubsub-js";
import { getOffset, getOuterHeight } from "@wearegood/good-utilities";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_HEADER = "#header";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class InPageLink {
  constructor(element) {
    this.inpageLinkElement = element;
    this.inpageURL = this.inpageLinkElement.getAttribute('href');

    this.headerHeight = 


    this.bindCustomMessageEvents();
  }

  goToPageSection(e) {
    e.preventDefault();
    
    const PAGE_SECTION = document.querySelector(this.inpageURL);
    const HEADER = document.querySelector(SEL_HEADER);

    if(PAGE_SECTION) {
      const TOP_OFFSET = getOffset(PAGE_SECTION).top;
      const HEADER_HEIGHT = getOuterHeight(HEADER);

      const SCROLL_OFFSET = TOP_OFFSET - HEADER_HEIGHT;

      window.scroll({
        top: SCROLL_OFFSET,
        left: 0,
        behavior: 'smooth'
      });

      PubSub.publish('navigation/scroll');
    }
  }

  bindCustomMessageEvents() {
    this.inpageLinkElement.addEventListener("goToPageSection", this.goToPageSection.bind(this));
  }
}