"use strict";

import { ready, bindGlobalMessages } from "@wearegood/good-utilities";

//import ModalAPI from "Modules/Modal";
import SmartImageAPI from "Modules/SmartImage";
import NavigationAPI from "Modules/Navigation";
import AnimationAPI from "Modules/Animation"; 
import VideoEmbedAPI from "Modules/VideoEmbedPlayer";  

/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */ 
function initialiseComponentModules() { 
  bindGlobalMessages();

  NavigationAPI.initialiseInPageNav();
  NavigationAPI.initialiseNavigation();
  SmartImageAPI.initSmartImages(); 
  //ModalAPI.initialiseModals();
  AnimationAPI.initParallax();
  VideoEmbedAPI.initVideoEmbedPlayers();
}

ready(initialiseComponentModules);
