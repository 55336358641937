////////////////////
// Module Imports //
//////////////////// 


import "nodelist-foreach-polyfill";
import { createDelegatedEventListener } from "@wearegood/good-utilities";

import InPageLink from "Modules/Navigation/InPageLink";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_INPAGE_LINK = "[data-inpage-link=component]";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

function initialiseInPageLinks() {
  const INPAGE_LINKS = document.querySelectorAll(SEL_INPAGE_LINK);

  INPAGE_LINKS.forEach(element => {
    let inpageLink = new InPageLink(element); 
  })
}

/**
 * delegateEvents - Create delegated event listeners for the components managed within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  createDelegatedEventListener("click", SEL_INPAGE_LINK, "goToPageSection");
}


/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export default function initialiseInPageNav() {
  // Create delegated event listeners for the components within this module
  delegateEvents(); 
  initialiseInPageLinks();
}