
////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";
import "intersection-observer";
import { createDelegatedEventListener } from "@wearegood/good-utilities";

import VideoEmbedPlayer from "Modules/VideoEmbedPlayer/VideoEmbedPlayer";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_VIDEO_EMBED_PLAYER = "[data-video-embed-player=component]";
const SEL_VIDEO_EMBED_OPEN = "[data-video-embed-player=open]";
const SEL_VIDEO_EMBED_CLOSE = "[data-video-embed-player=close]";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

function delegateEvents() { 
  createDelegatedEventListener("click", SEL_VIDEO_EMBED_OPEN, "openVideo");
  createDelegatedEventListener("click", SEL_VIDEO_EMBED_CLOSE, "closeVideo");
}

/**
 *
 *
 */
function initialiseVideoEmbedPlayers() {
  const VIDEO_EMBED_PLAYERS = document.querySelectorAll(SEL_VIDEO_EMBED_PLAYER);

  VIDEO_EMBED_PLAYERS.forEach(element => {
    let videoPlayer = new VideoEmbedPlayer(element);
  })
}

export default function initVideoEmbedPlayers() {
  initialiseVideoEmbedPlayers();
  delegateEvents(); 
}