
////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";
import "intersection-observer";
import { createCustomEvent } from "@wearegood/good-utilities";
import ParallaxPanel from "Modules/Animation/ParallaxPanel";
import ParallaxContainer from "Modules/Animation/ParallaxContainer";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_PARALLAX_CONTAINER = "[data-parallax=container]";
const SEL_PARALLAX_PANEL = "[data-parallax=panel]";

// Image Observer
const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 0
};

let panelObserver;
let containerObserver;  

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

const thresholdArray = function (num) {
  const arr = []
  const levels = num
  while (num--) {
    arr[num] = num / levels
  }
  return arr
}


/**
 * Handle observed intersections for parallax panel components
 *
 * @param {*} entries
 * @param {*} observer
 */
function handleParallaxPanelIntersection(entries, observer) {
  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      entry.target.dispatchEvent(createCustomEvent("parallaxPanelObservedInView", { thresholdRatio: entry.intersectionRatio}));
    }
  });
}


function handleParallaxContainerIntersection(entries, observer) {
  entries.forEach(function (entry) {
    entry.target.dispatchEvent(createCustomEvent("parallaxContainerObservedIntersecting", { thresholdRatio: entry.intersectionRatio }));
  });
}

/**
 * Instantiate an IntersectionObserver object for the parallax panel components
 *
 */
function initialiseParallaxPanelObserver() {
  if (typeof (window.IntersectionObserver) !== 'undefined') {
    OBSERVER_OPTIONS.threshold = thresholdArray(10);
    panelObserver = new IntersectionObserver(handleParallaxPanelIntersection, OBSERVER_OPTIONS);
  }
}

/**
 *
 *
 */
function initialiseParallaxContainerObserver() {
  if (typeof (window.IntersectionObserver) !== 'undefined') {
    OBSERVER_OPTIONS.threshold = 0;
    containerObserver = new IntersectionObserver(handleParallaxContainerIntersection, OBSERVER_OPTIONS);
  }
}
 
/**
 *
 *
 */
function initialiseParallaxPanels() {
  const PARALLAX_PANELS = document.querySelectorAll(SEL_PARALLAX_PANEL);

  PARALLAX_PANELS.forEach(element => {
    let parallaxPanel = new ParallaxPanel(element, panelObserver);
  })
}

/**
 *
 *
 */
function initialiseParallaxContainers() {
  const PARALLAX_CONTAINERS = document.querySelectorAll(SEL_PARALLAX_CONTAINER);
  console.log(containerObserver);
  PARALLAX_CONTAINERS.forEach(element => {
    let parallaxContainer = new ParallaxContainer(element, containerObserver);
  })
}

/**
 *
 *
 * @export
 */
export default function initParallax() {
  initialiseParallaxContainerObserver();
  initialiseParallaxPanelObserver();
  
  initialiseParallaxContainers();
  initialiseParallaxPanels();
}