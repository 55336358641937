
/////////////
// Imports //
/////////////

import { createNodeFromHTML, createDelegatedEventListener, createCustomEvent, createGlobalMessenger as createGlobal, messages as MESSAGES, trackPageEvent } from "@wearegood/good-utilities";


///////////////
// Constants //
///////////////

const CLASS_VIDEO_DISPLAYED = "is_Visible";
const SEL_VIDEO_POSTER = "[data-video-embed-player=poster]";

const IFRAME_TEMPLATE = `
  <iframe class="cp_Video__iframe" data-video-embed-player="iframe"></iframe>
`

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class VideoEmbedPlayer {
  constructor(element) {
    this.videoPlayer = element;
    this.videoURL = this.videoPlayer.dataset.videoUrl;
    this.videoIframe = createNodeFromHTML(IFRAME_TEMPLATE).item(0);
    this.videoLoaded = false;

    this.bindCustomMessageEvents();
  }

  openVideo(e) {
    e.preventDefault();
    // If the video has previously been loaded, don't do it again. Just display
    // the existing iframe.
    if(!this.videoLoaded && this.videoURL) {
      if (this.videoURL.indexOf('youtube') > -1) {
        if (this.videoURL.indexOf('?') > -1 && this.videoURL.indexOf('enablejsapi=1') === -1) {
          this.videoURL = this.videoURL + '&enablejsapi=1';
        }
      }

      this.videoIframe.setAttribute('src', this.videoURL);
      this.videoPlayer.appendChild(this.videoIframe);

    }

    this.videoPlayer.classList.add(CLASS_VIDEO_DISPLAYED);
    this.videoLoaded = true;
  }

  closeVideo(e) {
    e.preventDefault();
    this.videoPlayer.classList.remove(CLASS_VIDEO_DISPLAYED);
    this.videoPlayer.removeChild(this.videoIframe);
    this.videoLoaded = false;
  }

  bindCustomMessageEvents() {
    this.videoPlayer.addEventListener("openVideo", this.openVideo.bind(this));
    this.videoPlayer.addEventListener("closeVideo", this.closeVideo.bind(this));
  }
}