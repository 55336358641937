
////////////////////
// Module Imports //
//////////////////// 

import PubSub from "pubsub-js";
import { messages as MESSAGES, createCustomEvent } from "@wearegood/good-utilities";
 
////////////////////// 
// Module Constants //
//////////////////////


////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class ParallaxContainer {
  
  /**
   * Creates an instance of ParallaxContainer.
   * @param {*} element
   * @param {*} observer
   * @memberof ParallaxContainer
   */
  constructor(element, observer) {
    this.containerElem = element;
    this.observer = observer;

    this.observer.observe(this.containerElem); 

    this.inView = false;

    this.bindCustomMessageEvents();
    this.subscribeToEvents();
  }

  /** 
   *
   *
   * @param {*} e
   * @memberof ParallaxContainer
   */
  updateOffset(e) {
    e.preventDefault();

    const elemOffset = this.containerElem.getBoundingClientRect().top;
    const scrollPos = window.scrollY;

    let calculatedOffset = (elemOffset * -1) + (window.innerHeight / 4);

    if(calculatedOffset < 0) {
      calculatedOffset = 0;
    }

    if (this.inView && calculatedOffset > 0) {
      calculatedOffset = calculatedOffset + 'px'; 
      this.containerElem.style.setProperty('--offset', calculatedOffset);
      this.containerElem.classList.add('is_Offset');
    } else {
      this.containerElem.classList.remove('is_Offset');
    }
  };

  checkInView(e) {
    const threshold = e.detail.data.thresholdRatio;

    if(threshold > 0) {
      this.inView = true;
    } else {
      this.inView = false;
    }
  }

  /**
   *
   *
   * @memberof ParallaxContainer
   */
  bindCustomMessageEvents() {
    this.containerElem.addEventListener(
      "updateOffset", 
      this.updateOffset.bind(this)
    );

    this.containerElem.addEventListener(
      "parallaxContainerObservedIntersecting",
      this.checkInView.bind(this)
    );
  }

  /**
   * Subscribe object to Global Messages
   *
   * @memberof ParallaxContainer
   */
  subscribeToEvents() {
    window.addEventListener('scroll', (event) => {
      // Publish global message
      this.updateOffset(event);
    }); 
  }
}



