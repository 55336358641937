
/////////////
// Imports //
/////////////

import "intersection-observer";
import PubSub from "pubsub-js";
import { createCustomEvent, messages as MESSAGES } from "@wearegood/good-utilities";

///////////////
// Constants //
///////////////

const CLASS_VISIBLE = "is_Visible";
const CLASS_STEP_BASE = "stage-";
const SEL_PANEl_CONTENT = "[data-parallax=content]";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class ParallaxPanel {
  constructor(element, observer) {
    this.panelElem = element;
    this.panelContentElem = this.panelElem.querySelector(SEL_PANEl_CONTENT);
    this.observer = observer;

    this.panelElem.classList.add('has_Motion');
    
    this.currThreshold = 0;
    this.prevThreshold = 0;
    
    this.observer.observe(this.panelElem); 
    this.bindCustomMessageEvents();
  }

  updateParallaxPanel(e) {
    this.currThreshold = e.detail.data.thresholdRatio;

    // Set the visibility of the panel contents, controlled by a single class
    if (this.currThreshold > this.prevThreshold) {
      const STAGE_NUMBER = Math.floor(this.currThreshold * 10);
      this.panelElem.classList.add(CLASS_STEP_BASE + STAGE_NUMBER);
    } else if (this.currThreshold < this.prevThreshold) {
      const STAGE_NUMBER = Math.ceil(this.currThreshold * 10);
      this.panelElem.classList.remove(CLASS_STEP_BASE + STAGE_NUMBER);
    }

    // Set the transform offset to keep the contents relatively fixed in position
    //this.panelContentElem.setAttribute('style','transform:translateY(calc((' + this.currThreshold + ' * 50vh)) * -1');

    // Update previous threshold for next time around...
    this.prevThreshold = this.currThreshold;
  }

  bindCustomMessageEvents() {
    this.panelElem.addEventListener(
      "parallaxPanelObservedInView",
      this.updateParallaxPanel.bind(this)
    );
  }
}
